.select {
  :global {
    .ant-select-selector {
      &::after {
        width: 20px;
      }
    }
  }
}

.popup {
  :global {
    .ant-select-item-option-content {
      white-space: normal !important;
    }
  }
}
